// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-films-js": () => import("./../../../src/pages/films.js" /* webpackChunkName: "component---src-pages-films-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-photos-js": () => import("./../../../src/pages/photos.js" /* webpackChunkName: "component---src-pages-photos-js" */),
  "component---src-templates-film-js": () => import("./../../../src/templates/film.js" /* webpackChunkName: "component---src-templates-film-js" */),
  "component---src-templates-photos-js": () => import("./../../../src/templates/photos.js" /* webpackChunkName: "component---src-templates-photos-js" */)
}

